import React, { useCallback, useMemo } from 'react';
import { useMutation, useQuery } from '@apollo/client';

import { RESTAURANTS, CREATE_RESTAURANT } from '../../lib/api';

import Select from '../Select';

const RestaurantTypeahead = ({ value, onChange, ...props }) => {
  const { data, loading, error } = useQuery(RESTAURANTS);
  const [createRestaurant] = useMutation(CREATE_RESTAURANT, { refetchQueries: [{ query: RESTAURANTS }] });

  const options = useMemo(() => {
    if (loading) return [];
    if (error) return [];

    return data.restaurants.map(restaurant => ({
      label: restaurant.name,
      value: restaurant.id,
    }));
  }, [data, loading, error]);

  const onCreateRestaurant = useCallback(
    async name => {
      const { data } = await createRestaurant({ variables: { name } });
      onChange({ label: data.createRestaurant.name, value: data.createRestaurant.id });
    },
    [createRestaurant, onChange]
  );

  return (
    <Select
      label="Restaurant"
      placeholder="Select Restaurant"
      noResultsText={false}
      value={value}
      isDisabled={loading}
      isLoading={loading}
      options={options}
      onChange={onChange}
      formatCreateLabel={label => `Create Restaurant - ${label}`}
      onCreateOption={onCreateRestaurant}
      createable
      {...props}
    />
  );
};

export default RestaurantTypeahead;
