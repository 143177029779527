import React from 'react';
import styled from 'styled-components';

import { spin, TRANSITION_FAST } from '../styles/theme';

import Label from './Label';

const StyledTags = styled.div`
  margin: 0 0 15px;

  &:last-child {
    margin: 0;
  }

  .Tags__tags {
    display: flex;
    align-items: center;
  }

  .Tags__clear {
    display: inline-flex;
    align-items: center;
    cursor: pointer;
    padding: 3px 5px;
    font-size: 10px;
    text-transform: uppercase;
  }
`;

const Tag = styled.div`
  display: inline-flex;
  align-items: center;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  background: ${props => props.color};
  margin-right: 8px;
  cursor: pointer;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  transform: scale(0.8);
  transition: transform ${TRANSITION_FAST};

  &:hover {
    transform: scale(1);
  }

  ${props => props.active && `transform: scale(1)`};
`;

const generateColors = (baseColor, angle) => {
  const colors = [];
  const numColors = Math.floor(360 / angle);
  for (let i = 0; i < numColors; i++) {
    colors.push(spin(baseColor, angle * i));
  }
  return colors;
};

const Tags = ({ label, value, onChange }) => {
  const colors = generateColors('#d3edf9', 45);
  const isColorSelected = colors.includes(value);

  return (
    <StyledTags>
      {label && <Label>{label}</Label>}
      <div className="Tags__tags">
        {colors.map((color, i) => (
          <Tag key={i} active={value === color} color={color} onClick={() => onChange(color)} />
        ))}
        {isColorSelected && (
          <div className="Tags__clear" key="CLEAR" onClick={() => onChange('')}>
            Clear Selection
          </div>
        )}
      </div>
    </StyledTags>
  );
};

export default Tags;
