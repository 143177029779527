import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import noop from 'lodash/noop';

import theme, { darken, TRANSITION_FAST } from '../../styles/theme';
import { actions as modalActions } from '../../modules/modal';
import { selectors } from '../../modules/user';
import useUser from '../../hooks/useUser';

import ButtonIcon from '../ButtonIcon';

const StyledLunch = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: stretch;
  background: ${props => (props.color ? props.color : `#d3edf9`)};
  font-size: 14px;
  margin: 0 0 4px;
  cursor: pointer;
  transition: box-shadow ${TRANSITION_FAST};

  &:hover {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  }

  &:last-child {
    margin: 0;
  }

  .Lunch__header {
    padding: 4px 12px 0;
    font-size: 10px;
    text-transform: uppercase;
    font-weight: 700;
    color: ${theme.font_primary};
  }

  .Lunch__details {
    padding: 8px 12px;

    .Lunch__event {
      color: ${theme.font_primary};
    }

    .Lunch__restaurant {
      font-size: 12px;
      font-style: italic;
      color: ${theme.font_secondary};
    }
  }

  .Lunch__actions {
    display: flex;
    align-self: stretch;
    align-items: stretch;

    .Lunch__action {
      flex: 1 1 auto;
      color: ${theme.font_primary};
      margin-right: 2px;

      &:last-child {
        margin: 0;
      }
    }
  }
`;

const CalendarLunch = ({ lunch, moment, onRemove, ...props }) => {
  const { isAdmin } = useUser();
  const dispatch = useDispatch();

  const location = useSelector(selectors.settingLocation);
  const onEditLunch = useCallback(
    e => {
      e.stopPropagation();
      dispatch(
        modalActions.showModal('editLunch', {
          details: { lunch, moment },
        })
      );
    },
    [dispatch, lunch, moment]
  );

  const onRemoveLunch = useCallback(
    e => {
      e.stopPropagation();
      dispatch(
        modalActions.showModal('confirm', {
          type: 'alert',
          title: `Remove Lunch '${lunch.name}' for ${moment.format('dddd, MMMM Do')}`,
          message: `Are you sure you want to remove this lunch?`,
          closeText: `Yes, Remove Lunch`,
          cancelText: `No, Cancel`,
          onClose: () => onRemove(lunch),
        })
      );
    },
    [dispatch, lunch, moment, onRemove]
  );

  const renderDetails = lunch => {
    if (lunch.name === lunch.restaurant.name) {
      return <div className="Lunch__event">{lunch.restaurant.name}</div>;
    }

    return (
      <>
        <div className="Lunch__event">{lunch.name}</div>
        <div className="Lunch__restaurant">{lunch.restaurant.name}</div>
      </>
    );
  };

  return (
    <StyledLunch color={lunch.tag || lunch.location.tag} onClick={noop} {...props}>
      {location === 'all' && <div className="Lunch__header">{lunch.location.name}</div>}
      <div className="Lunch__details">{renderDetails(lunch)}</div>
      {isAdmin && (
        <div className="Lunch__actions">
          <ButtonIcon
            className="Lunch__action"
            icon="pencil-alt"
            style={{ background: darken(lunch.tag || lunch.location.tag, 8) }}
            onClick={onEditLunch}
          />
          <ButtonIcon
            className="Lunch__action"
            icon="trash"
            style={{ background: darken(lunch.tag || lunch.location.tag, 8) }}
            onClick={onRemoveLunch}
          />
        </div>
      )}
    </StyledLunch>
  );
};

export default CalendarLunch;
