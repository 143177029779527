import moment from '../lib/moment';

const SEASONS = [
  { name: 'winter', start: moment('01', 'MM').startOf('month'), end: moment('02', 'MM').endOf('month') },
  { name: 'spring', start: moment('03', 'MM').startOf('month'), end: moment('05', 'MM').endOf('month') },
  { name: 'summer', start: moment('06', 'MM').startOf('month'), end: moment('08', 'MM').endOf('month') },
  { name: 'fall', start: moment('09', 'MM').startOf('month'), end: moment('11', 'MM').endOf('month') },
  { name: 'winter', start: moment('12', 'MM').startOf('month'), end: moment('12', 'MM').endOf('month') },
];

export const getSeason = (today = moment()) => {
  const [currentSeason] = SEASONS.filter((season) => {
    const seasonRange = moment.range([season.start, season.end]);
    return seasonRange.contains(today);
  });

  return currentSeason || { name: 'spring' };
};

export const getMonthBounds = (dateString) => {
  const month = dateString ? moment(dateString, 'YYYYMM') : moment().format('YYYYMM');

  const startDate = moment(month).startOf('month').startOf('week');

  const endDate = moment(month).endOf('month').endOf('week').add(1, 'week');

  return { monthString: month, startDate, endDate };
};
