import React, { useCallback } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import routes, { defaultRoute } from '../constants/routes';
import useUser from '../hooks/useUser';

const AppRouter = () => {
  const { hasRole } = useUser();

  const checkAuth = useCallback(
    role => {
      return role ? hasRole(role) : true;
    },
    [hasRole]
  );

  return (
    <Switch>
      {routes
        .filter(r => checkAuth(r.role))
        .map((route, i) => (
          <Route key={i} exact={route.exact} path={route.url} component={route.component} />
        ))}
      <Redirect to={defaultRoute} />
    </Switch>
  );
};

export default AppRouter;
