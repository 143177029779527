const { NODE_ENV, REACT_APP_ENV } = process.env;

const ENVIRONMENT = REACT_APP_ENV || 'local';
const AZURE_LOGIN_PATH = ENVIRONMENT === 'local' ? 'http://localhost:4000/azure/login' : '/azure/login';

const config = {
  AZURE_LOGIN_PATH,
  ENVIRONMENT,
  NODE_ENV,
};

export default config;
