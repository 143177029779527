/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

import Svg from './Svg';
import theme from '../styles/theme';

const [TEXT_COLOR, DIVIDER_COLOR] = ['#51545F', '#D0D2D3'];

const StyledLogo = styled.div`
  .st0 {
    fill: ${props => props.color || theme.A500};
  }

  .st1 {
    fill: ${props => props.color || TEXT_COLOR};
  }

  .st2 {
    fill: ${props => props.color || DIVIDER_COLOR};
  }
`;

const Logo = ({ color, height, ...props }) => (
  <StyledLogo color={color} {...props}>
    <Svg height={height || '50'} viewBox="0 0 332.531 79.039">
      <rect className="st2" x="193.201" y="1.903" width="3.014" height="75.029" />
      <g>
        <path
          className="st1"
          d="M43.003,34.386h-8.451V17.24c0-1.809-0.246-3.125-0.74-3.948c-0.618-1.15-1.79-1.727-3.517-1.727
          c-1.726,0-2.857,0.802-3.391,2.406c-0.33,0.904-0.494,2.446-0.494,4.626v15.789H17.96V17.24c0-1.809-0.226-3.125-0.679-3.948
          c-0.657-1.15-1.83-1.727-3.515-1.727c-1.153,0-2.077,0.381-2.776,1.141c-0.699,0.762-1.049,1.718-1.049,2.869v18.811H1.493V3.918
          H8.4L8.71,5.151c1.602-1.274,3.33-1.912,5.181-1.912c1.89,0,3.441,0.215,4.656,0.647c1.212,0.432,2.579,1.244,4.102,2.436
          c2.344-2.055,4.953-3.083,7.833-3.083c4.152,0,7.318,1.316,9.498,3.947c2.013,2.427,3.023,5.778,3.023,10.054V34.386z"
        />
        <path
          className="st1"
          d="M76.733,19.214c0,4.811-1.069,8.573-3.207,11.286c-2.385,3.003-5.921,4.503-10.608,4.503
          c-4.688,0-8.224-1.5-10.607-4.503c-2.139-2.714-3.209-6.476-3.209-11.286s1.07-8.593,3.209-11.348
          c2.384-3.001,5.919-4.502,10.607-4.502c4.687,0,8.224,1.501,10.608,4.502C75.664,10.62,76.733,14.403,76.733,19.214z
          M68.222,19.214c0-5.139-1.768-7.71-5.304-7.71c-3.537,0-5.304,2.571-5.304,7.71c0,5.099,1.768,7.648,5.304,7.648
          C66.454,26.861,68.222,24.312,68.222,19.214z"
        />
        <path
          className="st1"
          d="M106.581,25.073c0,3.085-1.295,5.573-3.885,7.463c-2.304,1.645-5.057,2.467-8.265,2.467
          c-7.154,0-11.37-2.795-12.643-8.388l7.277-1.974c0.782,1.933,2.529,2.899,5.243,2.899c2.63,0,3.947-0.699,3.947-2.097
          c0-1.111-1.4-2.077-4.194-2.9c-4.03-1.192-6.641-2.117-7.834-2.776c-2.795-1.644-4.193-4.009-4.193-7.093
          c0-3.042,1.13-5.406,3.392-7.093c2.056-1.52,4.668-2.282,7.834-2.282c2.878,0,5.18,0.442,6.907,1.326
          c1.727,0.884,3.35,2.478,4.872,4.78l-6.414,3.577c-1.153-1.604-2.672-2.406-4.564-2.406c-2.386,0-3.577,0.7-3.577,2.097
          c0,0.946,1.377,1.789,4.131,2.529c3.825,0.946,6.415,1.83,7.772,2.652C105.182,19.502,106.581,21.907,106.581,25.073z"
        />
        <path
          className="st1"
          d="M137.909,20.633v0.555h-17.639c0.368,3.907,2.343,5.859,5.919,5.859
          c1.851,0,3.763-1.089,5.737-3.268l5.674,5.612c-1.892,1.892-3.598,3.249-5.12,4.071c-1.891,0.986-4.009,1.48-6.352,1.48
          c-4.483,0-8.06-1.521-10.732-4.565c-2.509-2.918-3.761-6.639-3.761-11.163c0-4.564,1.213-8.285,3.639-11.163
          c2.549-3.084,6.065-4.626,10.545-4.626c2.301,0,4.483,0.74,6.539,2.22c2.056,1.481,3.536,3.311,4.439,5.49
          C137.54,12.861,137.909,16.028,137.909,20.633z M130.139,15.944c-0.413-3.164-1.976-4.749-4.687-4.749
          c-2.838,0-4.544,1.584-5.12,4.749H130.139z"
        />
        <path
          className="st1"
          d="M163.443,5.46l-3.085,6.476c-1.314-0.576-2.508-0.864-3.576-0.864c-2.919,0-4.379,1.418-4.379,4.256
          v19.058h-8.326V3.918h6.786l0.306,1.295c1.767-1.397,3.64-2.097,5.613-2.097C159.084,3.116,161.305,3.897,163.443,5.46z"
        />
        <path
          className="st1"
          d="M17.412,66.388c-1.133,1.217-2.156,2.061-3.067,2.53c-1.051,0.552-2.336,0.828-3.856,0.828
          c-2.929,0-5.197-1.007-6.799-3.025c-1.466-1.852-2.197-4.272-2.197-7.257c0-3.011,0.731-5.444,2.197-7.296
          c1.602-2.017,3.87-3.026,6.799-3.026c1.52,0,2.805,0.276,3.856,0.83c0.911,0.47,1.934,1.326,3.067,2.57l-1.948,1.824
          c-0.83-0.94-1.562-1.589-2.198-1.949c-0.773-0.442-1.7-0.663-2.778-0.663c-4.063,0-6.094,2.571-6.094,7.711
          c0,5.142,2.031,7.712,6.094,7.712c1.078,0,2.005-0.235,2.778-0.705c0.635-0.36,1.367-0.994,2.198-1.908L17.412,66.388z"
        />
        <path
          className="st1"
          d="M36.68,59.382c0,6.883-2.82,10.325-8.457,10.325c-5.667,0-8.5-3.429-8.5-10.283
          c0-6.938,2.833-10.407,8.5-10.407C33.86,49.017,36.68,52.473,36.68,59.382z M33.902,59.424c0-2.405-0.346-4.201-1.037-5.39
          c-0.884-1.521-2.446-2.28-4.684-2.28c-2.212,0-3.76,0.76-4.645,2.28c-0.69,1.189-1.037,2.985-1.037,5.39
          c0,2.377,0.346,4.145,1.037,5.306c0.885,1.521,2.433,2.281,4.645,2.281c2.238,0,3.8-0.76,4.684-2.281
          C33.555,63.569,33.902,61.801,33.902,59.424z"
        />
        <path
          className="st1"
          d="M56.013,69.416h-2.736V57.765c0-2.044-0.374-3.537-1.12-4.477c-0.829-1.023-2.239-1.534-4.229-1.534
          c-1.466,0-2.667,0.511-3.607,1.534c-0.939,1.023-1.409,2.266-1.409,3.732v12.396h-2.778V49.349h2.778v1.907
          c1.271-1.493,2.944-2.238,5.016-2.238c5.39,0,8.085,2.847,8.085,8.54V69.416z"
        />
        <path
          className="st1"
          d="M74.391,63.694c0,1.88-0.787,3.4-2.362,4.562c-1.438,0.994-3.152,1.491-5.141,1.491
          c-4.478,0-7.214-1.891-8.208-5.679l2.736-0.871c0.662,2.599,2.487,3.898,5.472,3.898c1.216,0,2.266-0.262,3.15-0.789
          c1.051-0.635,1.576-1.504,1.576-2.611c0-1.409-1.064-2.432-3.191-3.069c-1.965-0.58-3.927-1.145-5.889-1.699
          c-2.128-0.938-3.192-2.432-3.192-4.478c0-1.74,0.745-3.109,2.238-4.104c1.299-0.883,2.875-1.326,4.727-1.326
          c3.566,0,6.067,1.465,7.504,4.395l-2.488,1.201c-0.911-1.934-2.584-2.901-5.016-2.901c-1.051,0-1.962,0.194-2.738,0.58
          c-0.966,0.498-1.45,1.216-1.45,2.155c0,1.079,1.065,1.922,3.193,2.529c3.511,1.023,5.473,1.631,5.888,1.824
          C73.328,59.88,74.391,61.511,74.391,63.694z"
        />
        <path
          className="st1"
          d="M93.706,69.416h-2.736v-1.493c-1.355,1.217-3.028,1.823-5.017,1.823
          c-2.819,0-4.878-0.711-6.177-2.135c-1.3-1.423-1.95-3.683-1.95-6.779V49.349h2.778v11.484c0,2.13,0.359,3.663,1.078,4.602
          c0.829,1.078,2.253,1.617,4.271,1.617c1.354,0,2.53-0.407,3.524-1.223c0.995-0.815,1.493-1.901,1.493-3.255V49.349h2.736V69.416z"
        />
        <path
          className="st1"
          d="M104.051,69.416c-3.068,0-4.602-1.576-4.602-4.727V40.476h2.819v24.047
          c0,0.774,0.11,1.327,0.332,1.659c0.222,0.332,0.705,0.497,1.452,0.497V69.416z"
        />
        <path
          className="st1"
          d="M115.123,52.044h-3.11v12.479c0,0.994,0.193,1.617,0.579,1.865c0.277,0.194,0.954,0.29,2.032,0.29
          v2.737h-1.119c-2.792,0-4.188-1.63-4.188-4.893V52.044h-2.737v-2.736h2.737v-5.847h2.695v5.847h3.11V52.044z"
        />
        <path
          className="st1"
          d="M122.239,41.969c0,0.525-0.186,0.974-0.56,1.347c-0.373,0.373-0.823,0.56-1.348,0.56
          c-0.525,0-0.974-0.187-1.347-0.56c-0.373-0.373-0.559-0.822-0.559-1.347c0-0.525,0.186-0.974,0.559-1.348
          c0.373-0.373,0.823-0.56,1.347-0.56c0.525,0,0.975,0.187,1.348,0.56C122.053,40.995,122.239,41.444,122.239,41.969z
          M121.741,69.416h-2.82V49.349h2.82V69.416z"
        />
        <path
          className="st1"
          d="M142.551,69.416h-2.736V57.765c0-2.044-0.374-3.537-1.12-4.477
          c-0.829-1.023-2.238-1.534-4.229-1.534c-1.465,0-2.667,0.511-3.606,1.534c-0.94,1.023-1.409,2.266-1.409,3.732v12.396h-2.778
          V49.349h2.778v1.907c1.27-1.493,2.943-2.238,5.015-2.238c5.391,0,8.085,2.847,8.085,8.54V69.416z"
        />
        <path
          className="st1"
          d="M162.798,69.746c0,2.239-1.01,4.077-3.026,5.516c-1.798,1.298-3.857,1.948-6.179,1.948
          c-2.155,0-3.938-0.387-5.348-1.161c-1.77-0.968-2.654-2.432-2.654-4.395c0-0.718,0.124-1.466,0.373-2.238l2.446,0.871
          c-0.166,0.415-0.249,0.828-0.249,1.244c0,2.128,1.81,3.192,5.431,3.192c1.52,0,2.944-0.428,4.271-1.284
          c1.548-0.968,2.321-2.171,2.321-3.608c0-0.663-0.276-1.201-0.828-1.616c-0.554-0.415-1.175-0.621-1.866-0.621
          c-0.774,0-1.88,0.179-3.316,0.537c-1.439,0.36-2.544,0.539-3.317,0.539c-2.544,0-3.816-1.021-3.816-3.067
          c0-1.271,0.761-2.501,2.281-3.69c-1.991-1.133-2.985-3.082-2.985-5.845c0-2.239,0.662-3.973,1.989-5.203
          c1.328-1.23,3.111-1.845,5.35-1.845c2.099,0,3.799,0.539,5.1,1.617c0.773-0.221,1.132-0.884,1.076-1.99h2.695
          c0.028,2.074-0.69,3.441-2.155,4.104c0.386,0.968,0.58,2.073,0.58,3.317c0,4.643-2.433,6.965-7.296,6.965
          c-0.608,0-1.342-0.083-2.199-0.248c-1.271,0.801-1.906,1.698-1.906,2.695c0,0.606,0.386,0.91,1.161,0.91
          c0.773,0,1.901-0.2,3.379-0.6c1.478-0.4,2.604-0.602,3.379-0.602c1.437,0,2.654,0.388,3.649,1.161
          C162.244,67.205,162.798,68.337,162.798,69.746z M158.195,56.066c0-2.929-1.507-4.395-4.519-4.395
          c-3.041,0-4.561,1.466-4.561,4.395c0,3.041,1.533,4.559,4.601,4.559C156.703,60.625,158.195,59.107,158.195,56.066z"
        />
      </g>
      <g>
        <path
          className="st0"
          d="M227.645,25.143h21.647v5.617h-15.375v5.822h13.53v5.616h-13.53v6.028h15.58v5.617h-21.853V25.143z"
        />
        <path
          className="st0"
          d="M265.348,24.938h5.822l12.3,28.906h-6.601l-2.624-6.438h-12.137l-2.624,6.438h-6.437L265.348,24.938
          z M271.99,41.831l-3.812-9.308l-3.813,9.308H271.99z"
        />
        <path className="st0" d="M291.215,30.966h-8.733v-5.822h23.78v5.822h-8.732v22.878h-6.314V30.966z" />
        <path
          className="st0"
          d="M308.762,49.662l3.731-4.47c2.583,2.132,5.289,3.486,8.569,3.486c2.583,0,4.141-1.025,4.141-2.707
          v-0.082c0-1.599-0.983-2.419-5.781-3.649c-5.78-1.476-9.512-3.074-9.512-8.773v-0.082c0-5.207,4.182-8.651,10.045-8.651
          c4.183,0,7.749,1.312,10.66,3.649l-3.28,4.756c-2.542-1.763-5.043-2.829-7.462-2.829s-3.689,1.107-3.689,2.501v0.082
          c0,1.886,1.229,2.501,6.19,3.772c5.822,1.517,9.103,3.607,9.103,8.609v0.082c0,5.7-4.347,8.898-10.537,8.898
          C316.593,54.254,312.207,52.737,308.762,49.662z"
        />
      </g>
    </Svg>
  </StyledLogo>
);

export default Logo;
