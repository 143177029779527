import styled from 'styled-components';

import Button from './Button';

const ButtonIcon = styled(Button)`
  color: ${props => (props.color ? props.color : `inherit`)};
  transition: color 300ms;

  &:hover {
    color: ${props => (props.hover ? props.hover : `inherit`)};
  }
`;

export default ButtonIcon;
