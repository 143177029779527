/* eslint-disable */
import React from 'react';
import styled from 'styled-components';

import SvgIcon from './SvgIcon';

const StyledIcon = styled.div`
  display: inline-flex;

  .cls-1 {
    fill: #c96649;
  }

  .cls-2 {
    fill: #ffdfb8;
  }

  .cls-3 {
    fill: #b2bf4e;
  }

  .cls-4 {
    fill: #deab71;
  }

  .cls-5 {
    fill: #534741;
  }
`;

const IconPizza = props => (
  <StyledIcon>
    <SvgIcon viewBox="0 0 103.01 147.05" {...props}>
      <path
        className="cls-1"
        d="M38.07,112.23c-4.19-5.12-14.65-6-18.87-.62C14.63,117.44,16.75,124,21,129.05l.08.12C26.83,123.59,32.5,118,38.07,112.23Z"
      />
      <path
        className="cls-1"
        d="M5.94,54.54C14,56,19.09,49.93,19.82,42.29S12.05,30.07,5.26,31.48c.09,2.39.14,4.79.23,7.16C5.68,43.94,5.82,49.24,5.94,54.54Z"
      />
      <path
        className="cls-1"
        d="M39.36,81.59c2.31-6.55.32-11.26-5.79-14.42-5.9-2.28-13-.42-16.29,5.29-3.5,6,.07,11.92,5.52,15.2C29.75,91.85,36.72,89.07,39.36,81.59Z"
      />
      <path
        className="cls-1"
        d="M56.79,72.34c-5.22,6-2.14,12.18,3.37,16.37Q68,80.12,75.6,71.37C70.05,66.61,61.82,66.53,56.79,72.34Z"
      />
      <path
        className="cls-1"
        d="M40.3,42c.81,3.51,4.68,6.93,7.68,8.57,8.92,4.89,14.91-1.58,16.7-9.68A150.16,150.16,0,0,0,44.12,32C41.53,34.57,39.45,38.24,40.3,42Z"
      />
      <path
        className="cls-2"
        d="M88.58,56.06A161.64,161.64,0,0,0,66.76,42c-1,4.35-2.93,9-7,11.17s-9,1.56-13-.64c-8.59-4.8-11.64-14.06-5.11-21.23A133.13,133.13,0,0,0,4.8,24.75c.16,1.48.27,3,.35,4.46C12.9,27.8,22.6,33,22.17,41.69,21.71,51,15.36,58.12,6,56.8L6.11,64a7.54,7.54,0,0,0,4.37-2,1.22,1.22,0,0,1,1.7.23L14,63.86a1.12,1.12,0,0,1,0,1.64A12.31,12.31,0,0,1,6.19,69c0,1.67,0,3.33.07,5,.3,22.74.26,45.51,1.24,68.23q6.06-5.69,12-11.49l-.09-.08c-5.39-6.36-7.52-15.5-.7-22,5.46-5.18,16.09-3.36,20.87,2,3.09-3.2,6.17-6.4,9.22-9.64q5-5.26,9.82-10.62C52.18,85.33,49,78.1,55,70.93c5.76-6.93,15.64-6.77,22.09-1.26,2.43-2.8,4.86-5.6,7.26-8.42C85.73,59.62,87.09,57.7,88.58,56.06Zm-63.07-7a7.13,7.13,0,0,0,1.24-8.44,1.11,1.11,0,0,1,.34-1.39l2.26-1.66a1.1,1.1,0,0,1,1.6.32,12.12,12.12,0,0,1-1.88,14.67,1.12,1.12,0,0,1-1.64,0l-1.69-1.82C25.15,50.14,25.13,49.49,25.51,49.07ZM12.62,106.13a1.13,1.13,0,0,1-1.64.05L9.25,104.4c-.61-.62-.63-1.27-.27-1.69a7.14,7.14,0,0,0,1-8.47,1.1,1.1,0,0,1,.31-1.4l2.22-1.71a1.1,1.1,0,0,1,1.61.28A12.16,12.16,0,0,1,12.62,106.13ZM23.05,90.39C16.28,87,11.18,80,14.72,72.49a15.4,15.4,0,0,1,19.62-7.43l.07,0,.2.08c7.87,4,9.7,10.69,6.27,18.68C37.73,91.18,30.3,94,23.05,90.39ZM48,97.05a12.14,12.14,0,0,1-14.48,3,1.12,1.12,0,0,1-.55-1.54l1.16-2.2c.41-.77,1-1,1.53-.77A7.14,7.14,0,0,0,44,94a1.09,1.09,0,0,1,1.42-.13l2.31,1.59A1.1,1.1,0,0,1,48,97.05ZM50.9,67A1.1,1.1,0,0,1,49.66,68,12.15,12.15,0,0,1,39.28,57.47a1.12,1.12,0,0,1,1-1.29L42.76,56c.86-.07,1.38.31,1.47.87A7.13,7.13,0,0,0,50.11,63,1.11,1.11,0,0,1,51,64.16C51,65,50.92,66.11,50.9,67Zm26.67-8.09-1.69,1.82c-.59.64-1.23.7-1.68.35a7.14,7.14,0,0,0-8.51-.62,1.09,1.09,0,0,1-1.4-.24l-1.83-2.13a1.1,1.1,0,0,1,.2-1.62,12.15,12.15,0,0,1,14.78.81A1.12,1.12,0,0,1,77.57,58.87Z"
      />
      <path
        className="cls-3"
        d="M14,63.86l-1.81-1.7a1.22,1.22,0,0,0-1.7-.23,7.54,7.54,0,0,1-4.37,2c0,1.67,0,3.33.08,5A12.31,12.31,0,0,0,14,65.5,1.12,1.12,0,0,0,14,63.86Z"
      />
      <path
        className="cls-3"
        d="M27.43,52.59a1.12,1.12,0,0,0,1.64,0A12.12,12.12,0,0,0,31,37.9a1.1,1.1,0,0,0-1.6-.32l-2.26,1.66a1.11,1.11,0,0,0-.34,1.39,7.13,7.13,0,0,1-1.24,8.44c-.38.42-.36,1.07.23,1.7Z"
      />
      <path
        className="cls-3"
        d="M50.11,63a7.13,7.13,0,0,1-5.88-6.19c-.09-.56-.61-.94-1.47-.87l-2.48.21a1.12,1.12,0,0,0-1,1.29A12.15,12.15,0,0,0,49.66,68,1.1,1.1,0,0,0,50.9,67c0-.85.06-2,.09-2.8A1.11,1.11,0,0,0,50.11,63Z"
      />
      <path
        className="cls-3"
        d="M62.66,56.43a1.1,1.1,0,0,0-.2,1.62l1.83,2.13a1.09,1.09,0,0,0,1.4.24A7.14,7.14,0,0,1,74.2,61c.45.35,1.09.29,1.68-.35l1.69-1.82a1.12,1.12,0,0,0-.13-1.63A12.15,12.15,0,0,0,62.66,56.43Z"
      />
      <path
        className="cls-3"
        d="M45.44,93.84A1.09,1.09,0,0,0,44,94a7.14,7.14,0,0,1-8.39,1.6c-.52-.22-1.12,0-1.53.77l-1.16,2.2a1.12,1.12,0,0,0,.55,1.54,12.14,12.14,0,0,0,14.48-3,1.1,1.1,0,0,0-.22-1.62Z"
      />
      <path
        className="cls-3"
        d="M12.56,91.13l-2.22,1.71a1.1,1.1,0,0,0-.31,1.4,7.14,7.14,0,0,1-1,8.47c-.36.42-.34,1.07.27,1.69L11,106.18a1.13,1.13,0,0,0,1.64-.05,12.16,12.16,0,0,0,1.55-14.72A1.1,1.1,0,0,0,12.56,91.13Z"
      />
      <path
        className="cls-4"
        d="M96,34.42a76.18,76.18,0,0,0-9.15-8.25C74.16,16.42,58.73,9.62,43.19,6A113.82,113.82,0,0,0,20.44,3.16C15.83,3,9,2.38,4.88,4.92c-3.76,3.95-1.6,11.5-.54,16.82C35.78,22.53,66.06,35,90.83,54a22.51,22.51,0,0,0,2.64-1.82A23.56,23.56,0,0,0,99,44.14C100.81,40,98.94,37.58,96,34.42Z"
      />
      <path
        className="cls-5"
        d="M93.66,28C80.67,16.26,64.11,8.57,47.37,4A112.83,112.83,0,0,0,21.14.19C15.76,0,8-.66,3.21,2.42a1.31,1.31,0,0,0-.36.27c-3.24,3.25-3,9.63-2.62,13.8C.77,22,2,27.15,2.24,32.69,2.82,45,3,57.25,3.2,69.53c.36,25.34.23,50.72,1.43,76a1.53,1.53,0,0,0,2.57,1.06q22.58-21,43.75-43.52Q61.38,92,71.46,80.62,76.59,74.83,81.65,69c3.54-4.1,6.86-8.95,11.07-12.39,4.52-3.71,8.64-8.14,10.09-14S97.53,31.44,93.66,28ZM60.16,88.71c-5.51-4.19-8.59-10.36-3.37-16.37,5-5.81,13.26-5.73,18.81-1Q68,80.13,60.16,88.71Zm16.91-19C70.62,64.16,60.74,64,55,70.93c-5.95,7.17-2.8,14.4,3.67,19.44Q53.78,95.73,48.83,101c-3,3.24-6.13,6.44-9.22,9.64-4.78-5.32-15.41-7.14-20.87-2-6.82,6.47-4.69,15.61.7,22l.09.08q-6,5.79-12,11.49c-1-22.72-.94-45.49-1.24-68.23,0-1.67,0-3.33-.07-5s-.05-3.33-.08-5L6,56.8C15.36,58.12,21.71,51,22.17,41.69,22.6,33,12.9,27.8,5.15,29.21c-.08-1.5-.19-3-.35-4.46a133.13,133.13,0,0,1,36.94,6.5c-6.53,7.17-3.48,16.43,5.11,21.23,3.94,2.2,8.85,2.83,13,.64s6-6.82,7-11.17A161.64,161.64,0,0,1,88.58,56.06c-1.49,1.64-2.85,3.56-4.25,5.19C81.93,64.07,79.5,66.87,77.07,69.67ZM21,129.05c-4.29-5.06-6.41-11.61-1.84-17.44,4.22-5.38,14.68-4.5,18.87.62C32.5,118,26.83,123.59,21.12,129.17ZM19.82,42.29C19.09,49.93,14,56,5.94,54.54c-.12-5.3-.26-10.6-.45-15.9-.09-2.37-.14-4.77-.23-7.16C12.05,30.07,20.56,34.55,19.82,42.29ZM48,50.53c-3-1.64-6.87-5.06-7.68-8.57-.85-3.72,1.23-7.39,3.82-9.92a150.16,150.16,0,0,1,20.56,8.81C62.89,49,56.9,55.42,48,50.53Zm45.49,1.69A22.51,22.51,0,0,1,90.83,54c-24.77-19-55-31.51-86.49-32.3C3.28,16.42,1.12,8.87,4.88,4.92,9,2.38,15.83,3,20.44,3.16A113.82,113.82,0,0,1,43.19,6c15.54,3.64,31,10.44,43.64,20.19A76.18,76.18,0,0,1,96,34.42c3,3.16,4.83,5.58,3,9.72A23.56,23.56,0,0,1,93.47,52.22Z"
      />
      <path
        className="cls-5"
        d="M40.88,83.85c3.43-8,1.6-14.65-6.27-18.68l-.2-.08-.07,0a15.4,15.4,0,0,0-19.62,7.43C11.18,80,16.28,87,23.05,90.39,30.3,94,37.73,91.18,40.88,83.85ZM17.28,72.46c3.31-5.71,10.39-7.57,16.29-5.29,6.11,3.16,8.1,7.87,5.79,14.42-2.64,7.48-9.61,10.26-16.56,6.07C17.35,84.38,13.78,78.47,17.28,72.46Z"
      />
    </SvgIcon>
  </StyledIcon>
);

export default IconPizza;
