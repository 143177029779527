import React, { useEffect, useCallback, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMutation } from '@apollo/client';
import styled from 'styled-components';
import isEmpty from 'lodash/isEmpty';
import get from 'lodash/get';

import { LUNCHES, CREATE_LUNCH, UPDATE_LUNCH } from '../../lib/api';
import { getMonthBounds } from '../../utils/dates';

import { selectors } from '../../modules/user';
import { actions as modalActions } from '../../modules/modal';

import Input from '../Input';
import { LocationTypeahead } from '../Locations';
import { RestaurantTypeahead } from '../Restaurants';
import Tags from '../Tags';
import ModalWrapper from './ModalWrapper';

const Section = styled.div`
  transition: opacity 300ms;
  ${props => props.disabled && `opacity: 0`};
`;

const parseLunch = details => {
  const { location, lunch } = details;
  if (lunch) {
    return {
      location: { label: get(lunch, 'location.name'), value: get(lunch, 'location.id') },
      restaurant: { label: get(lunch, 'restaurant.name'), value: get(lunch, 'restaurant.id') },
      name: lunch.name,
      tag: lunch.tag,
    };
  }

  if (location) {
    return {
      location: { label: get(location, 'name'), value: get(location, 'id') },
    };
  }

  return {};
};

const LunchModal = ({ details, ...props }) => {
  const dispatch = useDispatch();

  const locationId = useSelector(selectors.settingLocation);
  const queryVariables = useMemo(() => {
    const { startDate, endDate } = getMonthBounds(details.moment);
    const variables = { startDate, endDate };

    if (locationId !== 'all') variables.locationId = locationId;
    return variables;
  }, [details, locationId]);

  const lunch = parseLunch(details);
  const [location, setLocation] = useState(lunch.location);
  const [restaurant, setRestaurant] = useState(lunch.restaurant);
  const [name, setName] = useState(lunch.name);
  const [tag, setTag] = useState(lunch.tag);

  const [createLunch] = useMutation(CREATE_LUNCH, {
    refetchQueries: [{ query: LUNCHES, variables: queryVariables }],
    onCompleted: () => {
      dispatch(modalActions.closeModal(props.id));
    },
  });

  const [updateLunch] = useMutation(UPDATE_LUNCH, {
    refetchQueries: [{ query: LUNCHES, variables: queryVariables }],
    onCompleted: () => {
      dispatch(modalActions.closeModal(props.id));
    },
  });

  useEffect(() => {
    const lunch = parseLunch(details);
    setLocation(lunch.location);
    setRestaurant(lunch.restaurant);
    setName(lunch.name);
    setTag(lunch.tag);
  }, [details]);

  const onCloseModal = useCallback(() => {
    const data = {
      date: details.moment.format('YYYY-MM-DD'),
      locationId: get(location, 'value'),
      restaurantId: get(restaurant, 'value'),
      tag: tag || null,
      name: name || null,
    };

    if (details.creating) {
      createLunch({ variables: { newLunchData: data } });
    } else {
      data.id = get(details, 'lunch.id');
      updateLunch({ variables: { lunchData: data } });
    }
  }, [details, location, name, restaurant, tag, createLunch, updateLunch]);

  const onUpdateLocation = useCallback(value => setLocation(value), []);
  const onUpdateName = useCallback(({ target }) => setName(target.value), []);
  const onUpdateTag = useCallback(value => setTag(value), []);
  const onUpdateRestaurant = useCallback(value => {
    setRestaurant(value);
    setName('');
  }, []);

  const valid = !isEmpty(location) && !isEmpty(restaurant);
  return (
    <ModalWrapper
      {...props}
      width={500}
      title={
        details.creating
          ? `Create Lunch for ${details.moment.format('dddd, MMMM Do')}`
          : `Edit Lunch for ${details.moment.format('dddd, MMMM Do')}`
      }
      closeText={details.creating ? `Create Lunch` : `Update Lunch`}
      onClose={onCloseModal}
      valid={valid}
      useCloseAction
      locked
    >
      <LocationTypeahead autoFocus onChange={onUpdateLocation} value={location} style={{ marginBottom: 25 }} />

      {location && (
        <RestaurantTypeahead onChange={onUpdateRestaurant} value={restaurant} style={{ marginBottom: 25 }} />
      )}

      {restaurant && (
        <Section>
          <Input
            label="Lunch Name (Optional)"
            placeholder={restaurant.label}
            name="name"
            value={name}
            style={{ marginBottom: 25 }}
            onChange={onUpdateName}
          />

          <Tags label="Color Tag (Optional, Defaults to Location Color)" onChange={onUpdateTag} value={tag} />
        </Section>
      )}
    </ModalWrapper>
  );
};

export default LunchModal;
