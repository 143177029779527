import React from 'react';
import styled from 'styled-components';

import theme from '../../styles/theme';

import Container from '../../layouts/Container';
import { LocationFilter } from '../Locations';

const StyledHeader = styled.header`
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background: rgb(244, 244, 244);
  border-bottom: 1px solid rgb(235, 235, 235);
  padding: 15px 20px;
  color: ${theme.font_primary};
  box-shadow: 0 20px 50px -20px rgba(0, 0, 0, 0.1);
  z-index: 2;

  .Header__left {
    flex: 1 1 auto;
  }

  .Header__right {
    margin-left: auto;
  }
`;

const CalendarHeader = () => {
  return (
    <StyledHeader>
      <Container className="Header__content">
        <LocationFilter />
      </Container>
    </StyledHeader>
  );
};

export default CalendarHeader;
