import styled from 'styled-components';

import Button from './Button';

const ButtonLink = styled(Button)`
  padding: 0;
  margin: 0;
  text-decoration: underline;
  cursor: pointer;
  font-size: 12px;
  font-weight: 700;
  text-transform: none;
  opacity: 1;
  color: ${props => (props.color ? props.color : `inherit`)};
  transition: color 300ms;

  &:hover {
    color: ${props => (props.hover ? props.hover : `inherit`)};
  }
`;

export default ButtonLink;
