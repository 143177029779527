import React from 'react';

import Container from './Container';
import Content from './Content';
import Calendar, { CalendarHeader } from '../components/Calendar';

const CalendarLayout = () => (
  <>
    <CalendarHeader />
    <Content style={{ paddingTop: 20 }}>
      <Container>
        <Calendar />
      </Container>
    </Content>
  </>
);

export default CalendarLayout;
