const DEFAULT_DURATION = 0.3;

export const fade = {
  transition: {
    duration: DEFAULT_DURATION,
  },
  variants: {
    enter: { opacity: 1 },
    exit: { opacity: 0 },
  },
  initial: 'exit',
  animate: 'enter',
  exit: 'exit',
};

export const fadeScale = {
  style: {
    originY: 0,
  },
  transition: {
    duration: DEFAULT_DURATION,
  },
  variants: {
    enter: {
      scale: 1,
      opacity: 1,
    },
    exit: {
      scale: 0.8,
      opacity: 0,
    },
  },
  initial: 'exit',
  animate: 'enter',
  exit: 'exit',
};
