import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  display: inline-flex;
  fill: currentColor;
  height: ${props => (props.height ? `${props.height}px` : 'auto')};
  width: ${props => (props.width ? `${props.width}px` : 'auto')};
`;

const Svg = ({ children, viewBox, color, width, height, ...props }) => (
  <StyledSvg fill={color} focusable="false" viewBox={viewBox} width={width} height={height} {...props}>
    {children}
  </StyledSvg>
);

Svg.defaultProps = {
  color: 'currentColor',
};

export default Svg;
