export const getLocalState = (key, defaultValue) => {
  const value = localStorage.getItem(key);
  return value ? JSON.parse(value) : defaultValue;
};

export const saveLocalState = (key, value, defaultValue) => {
  const savedValue = value || defaultValue;
  localStorage.setItem(key, JSON.stringify(savedValue));
  return savedValue;
};
