import { gql } from '@apollo/client';

// User
export const CURRENT_USER = gql`
  query CurrentUser {
    currentUser {
      id
      oid
      name
      email
      role {
        name
      }
    }
  }
`;

// Locations
export const LOCATIONS = gql`
  query Locations($name: String) {
    locations(name: $name) {
      id
      name
    }
  }
`;

export const LOCATION = gql`
  query Location($id: ID!) {
    location(id: $id) {
      id
      name
    }
  }
`;

// Restaurants
export const RESTAURANTS = gql`
  query Restaurants($name: String) {
    restaurants(name: $name) {
      id
      name
    }
  }
`;

export const RESTAURANT = gql`
  query Restaurant($id: ID!) {
    restaurant(id: $id) {
      id
      name
    }
  }
`;

export const CREATE_RESTAURANT = gql`
  mutation CreateRestaurant($name: String!) {
    createRestaurant(newRestaurantData: { name: $name }) {
      id
      name
    }
  }
`;

export const UPDATE_RESTAURANT = gql`
  mutation UpdateRestaurant($id: ID!, $name: String!) {
    updateRestaurant(id: $id, name: $name) {
      id
      name
    }
  }
`;

export const REMOVE_RESTAURANT = gql`
  mutation DeleteRestaurant($id: ID!) {
    deleteRestaurant(id: $id) {
      id
    }
  }
`;

// Lunches
export const LUNCHES = gql`
  query Lunches($startDate: DateTime, $endDate: DateTime, $locationId: ID) {
    lunches(startDate: $startDate, endDate: $endDate, locationId: $locationId) {
      id
      date
      name
      tag
      location {
        id
        name
        tag
      }
      restaurant {
        id
        name
      }
    }
  }
`;

export const LUNCH = gql`
  query Lunch($id: ID!) {
    lunch(id: $id) {
      id
      date
      name
      tag
      restaurant {
        id
        name
        homepage
        description
      }
    }
  }
`;

export const CREATE_LUNCH = gql`
  mutation CreateLunch($newLunchData: NewLunchInput!) {
    createLunch(newLunchData: $newLunchData) {
      id
      date
      name
      tag
      description
      location {
        id
        name
      }
      restaurant {
        id
        name
      }
    }
  }
`;

export const UPDATE_LUNCH = gql`
  mutation UpdateLunch($lunchData: UpdateLunchInput!) {
    updateLunch(lunchData: $lunchData) {
      id
      date
      name
      tag
      description
      location {
        id
        name
      }
      restaurant {
        id
        name
      }
    }
  }
`;

export const REMOVE_LUNCH = gql`
  mutation DeleteLunch($id: String!) {
    deleteLunch(id: $id) {
      id
    }
  }
`;

// Lunch Items
export const LUNCH_ITEMS = gql`
  query LunchItems($restaurantId: ID!) {
    lunchItems(restaurantId: $restaurantId) {
      id
      name
      description
      ratings {
        value
      }
      restaurant {
        id
        name
      }
    }
  }
`;

export const LUNCH_ITEM = gql`
  query LunchItem($id: ID!) {
    lunchItems(id: $id) {
      id
      name
      description
      ratings {
        value
      }
      restaurant {
        id
        name
      }
    }
  }
`;

export const CREATE_LUNCH_ITEM = gql`
  mutation CreateLunchItem($restaurantId: ID!, $name: String!) {
    createLunchItem(restaurantId: $restaurantId, name: $name) {
      id
      name
      description
      ratings {
        value
      }
      restaurant {
        id
        name
      }
    }
  }
`;

export const UPDATE_LUNCH_ITEM = gql`
  mutation UpdateLunchItem($id: ID!, $name: String!) {
    updateLunchItem(id: $id, name: $name) {
      id
      name
    }
  }
`;

export const REMOVE_LUNCH_ITEM = gql`
  mutation DeleteLunchItem($id: ID!) {
    deleteLunchItem(id: $id) {
      id
    }
  }
`;

// Session
export const clearSession = () => fetch('/logout', { credentials: 'same-origin', method: 'POST' });
