import React from 'react';
import styled from 'styled-components';

import * as IconMap from './Icons';

import minMax from '../utils/minMax';

const Backdrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.9);
  padding: 150px;
  z-index: 99;
`;

const StyledLoader = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;

  .Loader__icon {
    margin: 0 0 20px;
    transform-origin: bottom center;
    animation: wiggle 1.25s ease-in-out infinite;
  }

  .Loader__text {
    text-align: center;
    font-weight: 500;
    text-transform: uppercase;
  }
`;

const Loader = ({ inline, ...props }) => {
  const Icons = Object.keys(IconMap).map(IconName => IconMap[IconName]);
  const selected = minMax(0, Icons.length - 1);
  const LoaderComponent = Icons[selected];
  const Component = () => (
    <StyledLoader title="Loading...">
      <LoaderComponent className="Loader__icon" height={125} />
    </StyledLoader>
  );

  if (inline) return <Component />;

  return (
    <Backdrop>
      <Component />
    </Backdrop>
  );
};

export default Loader;
