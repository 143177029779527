const GREETINGS = [
  name => `What's cookin, <strong>${name}</strong>?`,
  name => `You want a pizza me, <strong>${name}</strong>?`,
  name => `Churro-k by me, <strong>${name}</strong>!`,
  name => `You're kind of a big dill, <strong>${name}</strong>...`,
  name => `You're one in a melon, <strong>${name}</strong>!`,
  name => `Muffin compares to you, <strong>${name}</strong>!`,
  name => `Wanna taco 'bout it, <strong>${name}</strong>?`,
  name => `I love you a waffle lot, <strong>${name}</strong>!`,
  name => `Fitness? More like fitness whole pizza in my mouth! Am I right, <strong>${name}</strong>?`,
  name => `'Ey <strong>${name}</strong>, wanna throw another shrimp on the barbie?`,
  name => `You're the greatest thing since sliced bread, <strong>${name}</strong>!`,
  name => `Have an egg-cellent day, <strong>${name}</strong>!`,
  name => `Lettuce us celebrate, <strong>${name}</strong>!`,
  name => `<strong>${name}</strong>... You butter believe it. `,
  name => `Nice to meat you, <strong>${name}</strong>. `,
  name => `Let’s cut to the cheese, <strong>${name}</strong>. `,
  name => `This may sound bananas but I find you a-peeling, <strong>${name}</strong>. `,
  name => `Salami-get this straight, <strong>${name}</strong>... `,
  name => `Penne for your thoughts, <strong>${name}</strong>?`,
  name => `Hope to see you again so we can ketchup, <strong>${name}</strong>.`,
  name => `Don’t go bacon my heart, <strong>${name}</strong>...`,
  name => `<strong>${name}</strong>, you’re my butter half! `,
  name => `We make a beautiful pear, <strong>${name}</strong>.`,
  name => `The s’more I know you, the s’more I love you, <strong>${name}</strong>.`,
  name => `Thanks for pudding up with me, <strong>${name}</strong>. `,
  name => `<strong>${name}</strong>, you’re a real pizzawork. `,
  name => `You look radishing today, <strong>${name}</strong>!`,
  name => `<strong>${name}</strong>, y’all bready for this?`,
  name => `Jello from the other side, <strong>${name}</strong>! `,
  name => `<strong>${name}</strong>, if you were a fruit, you’d be a fine apple. `,
  name => `Turnip for what, <strong>${name}</strong>?`,
  name => `I don’t believe you, <strong>${name}</strong>. You’re an impasta!`,
  name => `I value our friend-chip, <strong>${name}</strong>. `,
  name => `Donut you know you’re special, <strong>${name}</strong>?`,
  name => `You’ve stolen a pizza my heart, <strong>${name}</strong>! `,
  name => `Orange you so excited, <strong>${name}</strong>?`,
  name => `<strong>${name}</strong>, I love you from my head tomatoes.`,
  name => `You're a real funghi, <strong>${name}</strong>!`,
  name => `I’m your biggest flan, <strong>${name}</strong>.`,
  name => `You’re the apple of my ribeye, <strong>${name}</strong>.`,
  name => `<strong>${name}</strong>, Feeling a little melon-choly?`,
  name => `My heart beets for you, <strong>${name}</strong>.`,
  name => `I ap-peach-ate you, <strong>${name}</strong>!`,
  name => `I know it’s corny but you’re a-maize-ing, <strong>${name}</strong>!`,
];

export default GREETINGS;
