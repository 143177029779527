import styled from 'styled-components';

const StyledRating = styled.div`
  position: relative;

  .Rating__value,
  .Rating__backdrop {
    display: inline-flex;
    flex-flow: row nowrap;
  }

  .Rating__value {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  }

  .Rating__icon {
    box-sizing: content-box;
    padding-right: 5px;
    color: #dcdcdc;

    /* Size */
    font-size: ${props => (props.size ? `${props.size}px` : `30px`)};
    width: ${props => (props.size ? `${parseInt(props.size, 10) + 4}px` : `34px`)};
    height: ${props => (props.size ? `${props.size}px` : `30px`)};

    /* Editable */
    cursor: ${props => (props.editable ? 'pointer' : 'default')};

    &-active {
      color: #f7b83d;
    }

    &:last-child {
      padding: 0;
    }
  }
`;

export default StyledRating;
