import React, { Component } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import { Provider } from 'react-redux';

import store from '../lib/store';
import client from '../lib/apollo';

import GlobalStyles from '../styles/global';
import SiteLayout from '../layouts';
import AppRouter from './AppRouter';
import AuthGate from './AuthGate';
import ModalContainer from './Modal.container';
import ToastContainer from './Toast.container';

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ApolloProvider client={client}>
          <Router>
            <AuthGate>
              <SiteLayout>
                <AppRouter />
                <ModalContainer />
                <ToastContainer />
              </SiteLayout>
            </AuthGate>
          </Router>
          <GlobalStyles />
        </ApolloProvider>
      </Provider>
    );
  }
}

export default App;
