import React from 'react';
import styled from 'styled-components';

import { MOBILE_BREAKPOINT } from '../styles/global';
import theme, { FONT_LIGHT } from '../styles/theme';

import Greeting from './Greeting';
import Logo from './Logo';
import Container from '../layouts/Container';

const StyledFooter = styled.footer`
  position: relative;
  background: ${theme.font_primary};
  color: ${FONT_LIGHT};
  padding: 25px;
  box-shadow: 0 -20px 50px -20px rgba(0, 0, 0, 0.1);
  z-index: 1;

  .Footer__content {
    display: flex;
    align-items: center;

    @media (max-width: ${MOBILE_BREAKPOINT}) {
      flex-flow: column nowrap;
    }
  }

  .Footer__branding {
    margin-right: 15px;

    @media (max-width: ${MOBILE_BREAKPOINT}) {
      flex-flow: column nowrap;
      justify-content: center;
      margin-bottom: 15px;
    }
  }

  .Footer__greeting {
    margin-left: auto;

    @media (max-width: ${MOBILE_BREAKPOINT}) {
      margin-left: 0;
      text-align: center;
    }
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <Container>
        <div className="Footer__content">
          <div className="Footer__branding">
            <Logo height="35" color={theme.white} />
          </div>
          <div className="Footer__greeting">
            <Greeting />
          </div>
        </div>
      </Container>
    </StyledFooter>
  );
};

export default Footer;
