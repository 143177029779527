import React from 'react';
import styled from 'styled-components';

import Office365Logo from '../assets/logo-office365.svg';

import config from '../config';

import theme from '../styles/theme';
import Logo from '../components/Logo';

const Layout = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;

  .Layout__content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
  }

  .Layout__title {
    margin: 0 0 25px;

    img {
      height: auto;
      width: 200px;
    }
  }

  .Layout__button {
  }

  .Button__azure {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: ${theme.white};
    width: 300px;
    padding: 8px;
    border: 1px solid ${theme.B700};
    color: ${theme.font_primary};
    border-radius: 3px;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    cursor: pointer;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
    transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);

    &:hover,
    &:focus {
      opacity: 0.8;
    }

    &:active {
      transform: scale(0.95);
    }

    img {
      width: 28px;
      margin-right: 16px;
    }
  }
`;

const LoginLayout = () => (
  <Layout>
    <div className="Layout__content">
      <div className="Layout__title">
        <Logo />
      </div>
      <div className="Layout__button">
        <a className="Button__azure" href={config.AZURE_LOGIN_PATH}>
          <img src={Office365Logo} alt="Office 365 Logo" />
          <span>Sign in with Office 365</span>
        </a>
      </div>
    </div>
  </Layout>
);

export default LoginLayout;
