import React, { useMemo } from 'react';
import { useQuery } from '@apollo/client';

import { LOCATIONS } from '../../lib/api';

import Select from '../Select';

const LocationTypeahead = ({ value, onChange, ...props }) => {
  const { data, loading, error } = useQuery(LOCATIONS);

  const options = useMemo(() => {
    if (loading) return [];
    if (error) return [];

    return data.locations.map(location => ({
      label: location.name,
      value: location.id,
    }));
  }, [data, loading, error]);

  return (
    <Select
      label="Location"
      placeholder="Select Location"
      noResultsText={false}
      value={value}
      isLoading={loading}
      options={options}
      onChange={onChange}
      {...props}
    />
  );
};

export default LocationTypeahead;
