import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useMutation, useQuery } from '@apollo/client';
import { useSelector } from 'react-redux';

import moment from '../../lib/moment';
import { LUNCHES, REMOVE_LUNCH } from '../../lib/api';
import { selectors } from '../../modules/user';
import { getMonthBounds } from '../../utils/dates';

import Calendar from './Calendar';

const CalendarContainer = () => {
  const params = useParams();
  const history = useHistory();

  const locationId = useSelector(selectors.settingLocation);
  const { monthString, variables } = useMemo(() => {
    const { monthString, startDate, endDate } = getMonthBounds(params.month);
    const variables = { startDate, endDate };

    if (locationId !== 'all') variables.locationId = locationId;
    return { monthString, variables };
  }, [params.month, locationId]);

  const [month, setMonth] = useState(monthString);

  useEffect(() => {
    setMonth(monthString);
  }, [monthString]);

  const { data: lunchData } = useQuery(LUNCHES, {
    fetchPolicy: 'cache-and-network',
    variables,
  });

  const [removeLunch] = useMutation(REMOVE_LUNCH, {
    refetchQueries: [{ query: LUNCHES, variables }],
  });

  const onUpdateMonth = useCallback(
    momentMonth => {
      return history.push(`/calendar/${momentMonth.format('YYYYMM')}`);
    },
    [history]
  );

  const onCurrentMonth = useCallback(() => {
    const momentMonth = moment();
    onUpdateMonth(momentMonth);
  }, [onUpdateMonth]);

  const onNextMonth = useCallback(() => {
    const momentMonth = moment(month, 'YYYYMM').add(1, 'M');
    onUpdateMonth(momentMonth);
  }, [month, onUpdateMonth]);

  const onPreviousMonth = useCallback(() => {
    const momentMonth = moment(month, 'YYYYMM').subtract(1, 'M');
    onUpdateMonth(momentMonth);
  }, [month, onUpdateMonth]);

  const onRemoveLunch = useCallback(
    lunch => {
      removeLunch({ variables: { id: lunch.id } });
    },
    [removeLunch]
  );

  return (
    <>
      <Calendar
        month={moment(month, 'YYYYMM')}
        data={lunchData}
        onRemove={onRemoveLunch}
        onCurrent={onCurrentMonth}
        onNext={onNextMonth}
        onPrevious={onPreviousMonth}
      />
    </>
  );
};

export default CalendarContainer;
