import styled from 'styled-components';

import { MOBILE_BREAKPOINT } from '../../styles/global';
import theme from '../../styles/theme';

export const StyledCalendar = styled.div`
  display: grid;
  color: #3d3d3d;
  grid-template-columns: repeat(7, 1fr);
  grid-template-rows: repeat(3, auto);
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
`;

export const CalendarTitle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  grid-column: 1 / span 7;
  padding: 10px 15px;
  text-align: center;
  background: ${theme.A500};
  color: ${theme.white};

  .Calendar__title {
    flex: 1 1 auto;
    margin: 0 10px;
    font-size: 20px;
    font-weight: 700;
  }

  .Calendar__current {
    font-size: 12px;
    text-align: center;

    a {
      cursor: pointer;
      text-decoration: underline;
    }
  }

  .Calendar__left,
  .Calendar__right {
    flex: 0 0 auto;

    button {
      font-size: 24px;
    }
  }
`;

export const CalendarHeaders = styled.div`
  display: grid;
  width: 100%;
  grid-column: 1 / span 7;
  grid-template-columns: repeat(7, 1fr);
  background: ${theme.A500};
  color: ${theme.white};

  @media (max-width: 800px) {
    display: none;
  }

  .Calendar__header {
    padding: 10px 8px;
    text-align: right;
    font-size: 18px;
    font-weight: 700;
    text-transform: uppercase;
    border-right: 1px solid transparent;
  }
`;

export const CalendarGrid = styled.div`
  display: grid;
  width: 100%;
  background: #eeeeee;
  grid-column: 1 / span 7;
  grid-template-columns: repeat(7, 1fr);

  @media (max-width: ${MOBILE_BREAKPOINT}) {
    grid-template-columns: 1fr;
  }
`;

export default StyledCalendar;
