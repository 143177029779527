import styled from 'styled-components';

const Content = styled.div`
  padding: 45px;

  @media (max-width: 800px) {
    padding: 15px;
  }
`;

export default Content;
