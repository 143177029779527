import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import theme from '../styles/theme';
import routes, { defaultRoute } from '../constants/routes';
import { actions } from '../modules/user';
import useUser from '../hooks/useUser';

import Logo from './Logo';
import Container from '../layouts/Container';

const StyledHeader = styled.header`
  position: relative;
  background: ${theme.white};
  color: ${theme.font_primary};
  padding: 15px 25px;
  box-shadow: 0 20px 50px -20px rgba(0, 0, 0, 0.1);
  z-index: 1;

  .Header__content {
    display: flex;
    align-items: center;
  }

  .Header__branding {
    margin-right: 15px;
  }

  .Header__logo {
    width: auto;
    height: 50px;
  }

  .Header__nav {
    margin-left: auto;
  }

  a,
  button,
  .Header__item {
    display: inline-flex;
    align-items: center;
    background: transparent;
    border: none;
    padding: 20px;
    text-transform: uppercase;
    font-family: 'Maven Pro', Helvetica, sans-serif;
    font-weight: 500;
    font-size: 12px;
    color: ${theme.font_primary};
    cursor: pointer;
    text-decoration: none;
    outline: none;
    transition: color 300ms;

    &:hover {
      color: ${theme.A500};
    }
  }

  .Header__item {
    cursor: default;

    &:hover {
      background: transparent;
    }
  }
`;

const Header = () => {
  const dispatch = useDispatch();
  const { isAdmin } = useUser();

  const isAuthorized = useCallback(restricted => (restricted ? isAdmin : true), [isAdmin]);

  const onLogout = useCallback(() => {
    dispatch(actions.userLogout());
  }, [dispatch]);

  return (
    <StyledHeader>
      <Container className="Header__content">
        <div className="Header__branding">
          <Link to={defaultRoute} style={{ padding: '0' }}>
            <Logo />
          </Link>
        </div>
        <div className="Header__nav">
          {routes
            .filter(r => r.inMenu)
            .filter(r => isAuthorized(r.restricted))
            .map((route, i) => (
              <Link key={i} to={route.to}>
                {route.label}
              </Link>
            ))}
          <button onClick={onLogout}>Logout</button>
        </div>
      </Container>
    </StyledHeader>
  );
};

export default Header;
