import React from 'react';
import styled from 'styled-components';

import Header from '../components/Header';
import Footer from '../components/Footer';

const Layout = styled.div`
  height: 100vh;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-areas:
    'Header'
    'Main';

  .Site__header {
    grid-area: Header;
  }

  .Site__main {
    display: grid;
    grid-area: Main;
    grid-template-rows: 1fr auto;
    grid-template-areas:
      'Content'
      'Footer';
    overflow: auto;
  }

  .Site__content {
    grid-area: Content;
  }

  .Site__footer {
    grid-area: Footer;
  }
`;

const SiteLayout = ({ children }) => (
  <Layout>
    <header className="Site__header">
      <Header />
    </header>
    <section className="Site__main">
      <div className="Site__content">{children}</div>
      <footer className="Site__footer">
        <Footer />
      </footer>
    </section>
  </Layout>
);

export default SiteLayout;
