import { createActions, handleActions } from 'redux-actions';
import { all, call, takeEvery } from 'redux-saga/effects';
import auth from '../lib/auth';
import { getLocalState, saveLocalState } from '../utils/localStorage';

const defaultState = {
  settingLocation: getLocalState('USER_LOCATION', 'all'),
};

// Actions
export const actions = createActions('UPDATE_USER_LOCATION', 'USER_LOGOUT');

// Selectors
export const selectors = {
  settingLocation: state => state.user.settingLocation,
};

// Sagas
export function* userLogoutSaga() {
  yield call(auth.logout);
}

export function* userSaga() {
  yield all([takeEvery(actions.userLogout, userLogoutSaga)]);
}

// Reducer
export default handleActions(
  {
    [actions.updateUserLocation](state, { payload }) {
      return {
        ...state,
        settingLocation: saveLocalState('USER_LOCATION', payload),
      };
    },
  },
  defaultState
);
