import React, { useEffect, useState } from 'react';
import auth from '../lib/auth';
import Loader from './Loader';

const useInitAuth = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const init = async () => {
      await auth.init({ onLoad: 'login-required' });
      setLoading(false);
    };

    init();
  }, []);

  return loading;
};

const AuthGate = ({ children }) => {
  var loading = useInitAuth();
  if (loading) return <Loader />;
  return children;
};

export default AuthGate;
