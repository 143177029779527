// TODO - import { CalendarLayout, LunchLayout, RestaurantsLayout, RestaurantLayout } from 'layouts';
import { CalendarLayout } from '../layouts';

export const defaultRoute = '/calendar';

const routes = [
  {
    url: '/calendar/:month?',
    to: '/calendar',
    label: 'Calendar',
    component: CalendarLayout,
    inMenu: true,
  },
  // {
  //   to: '/restaurants',
  //   url: '/restaurants',
  //   label: 'Restaurants',
  //   component: RestaurantsLayout,
  //   exact: true,
  //   inMenu: true,
  // },
  // { url: '/lunches/:id', component: LunchLayout, inMenu: false },
  // { url: '/restaurants/:id', component: RestaurantLayout, restricted: true, inMenu: false },
];

export default routes;
