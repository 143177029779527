import { createGlobalStyle } from 'styled-components';

export const MOBILE_BREAKPOINT = '800px';

export default createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html,
  body,
  #root {
    min-height: 100vh;
  }

  body {
    background: #f8f8f8;
    margin: 0;
    padding: 0;
    font-family: 'Maven Pro', Helvetica, sans-serif;
    font-size: 18px;
    -webkit-font-smoothing: antialiased;
  }

  ::-webkit-input-placeholder {
    color: #aaa;
  }
  :-ms-input-placeholder {
    color: #aaa;
  }
  ::-moz-placeholder {
    color: #aaa;
    opacity: 1;
  }
  :-moz-placeholder {
    color: #aaa;
    opacity: 1;
  }

  @keyframes wiggle {
    0%, 100% {
      transform: scale(0.9);
    }

    75% {
      transform: scale(1);
    }

    90% {
      transform: scale(0.8);
    }
  }
`;
