import { useCallback, useMemo } from 'react';
import auth from '../lib/auth';

const useUser = () => {
  const data = useMemo(() => auth.tokenParsed, []);
  const hasRole = useCallback((role, resource = 'moser-eats') => {
    return auth.hasResourceRole(role, resource);
  }, []);

  const isAdmin = useMemo(() => {
    return hasRole('admin');
  }, [hasRole]);

  return { data, hasRole, isAdmin };
};

export default useUser;
