import React from 'react';
import styled from 'styled-components';

import Restaurant from './Restaurant';
import Loader from '../Loader';

const RestaurantGrid = styled.div`
  display: grid;
  grid-gap: 25px;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
`;

const Restaurants = ({ data, onCreate, onRemove, onUpdate }) => {
  const render = ({ loading, restaurants }) => {
    if (loading) return <Loader />;
    return restaurants.map(restaurant => (
      <Restaurant key={restaurant.id} restaurant={restaurant} onRemove={onRemove} />
    ));
  };

  return <RestaurantGrid>{render(data)}</RestaurantGrid>;
};

export default Restaurants;
