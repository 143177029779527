import React from 'react';
import styled from 'styled-components';

const StyledSvg = styled.svg`
  display: inline-block;
  fill: currentColor;
  height: ${props => (props.size ? `${props.size}px` : props.height ? `${props.height}px` : `auto`)};
  width: ${props => (props.size ? `${props.size}px` : props.width ? `${props.width}px` : `auto`)};
`;

const SvgIcon = ({ children, viewBox, color, size, ...props }) => (
  <StyledSvg fill={color} size={size} focusable="false" viewBox={viewBox} {...props}>
    {children}
  </StyledSvg>
);

SvgIcon.defaultProps = {
  color: 'currentColor',
  viewBox: '0 0 24 24',
};

export default SvgIcon;
