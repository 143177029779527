import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import theme from '../../styles/theme';
import { sampleRatings } from '../../utils/ratings';

import AdminOnly from '../AdminOnly';
import Button from '../Button';
import { StaticRating } from '../Rating';

const StyledRestaurant = styled.div`
  display: flex;
  flex-flow: column nowrap;
  background: ${theme.white};

  .Restaurant__divider {
    height: 1px;
    background: #dcdcdc;
    margin: 0 15px;
  }

  .Restaurant__details {
    display: flex;
    align-items: center;
    padding: 15px;
  }

  .Restaurant__info {
    flex: 1 1 auto;
  }

  .Restaurant__top {
    flex: 1 1 auto;
    padding: 15px 0;
  }

  .Restaurant__name {
    font-size: 20px;
    font-weight: 500;
  }

  .Restaurant__header {
    text-transform: uppercase;
    font-weight: 500;
    padding: 0 15px;
    font-size: 12px;
    margin: 0 0 8px;
  }

  .Restaurant__item {
    display: flex;
    padding: 5px 15px;

    &:last-child {
      border: none;
    }

    &-name {
      flex: 1 1 auto;
      margin-right: 10px;
    }

    &-rating {
      flex: 0 0 auto;
    }
  }

  .Restaurant__actions {
    display: flex;
    justify-content: flex-end;
    padding: 15px;
  }
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;

const Restaurant = ({ restaurant, onRemove }) => {
  return (
    <StyledRestaurant>
      <div className="Restaurant__details">
        <div className="Restaurant__info">
          <div className="Restaurant__name">{restaurant.name}</div>
          <div className="Restaurant__url">{restaurant.homepage}</div>
        </div>
        <div className="Restaurant__rating">
          <StaticRating ratings={sampleRatings()} size="22" />
        </div>
      </div>

      <div className="Restaurant__divider" />

      <div className="Restaurant__top">
        <div className="Restaurant__header">Most Popular Items</div>
        {restaurant.items.length === 0 && (
          <div className="Restaurant__item">
            <div className="Restaurant__item-name">No Items!</div>
          </div>
        )}
        {restaurant.items.slice(0, 3).map(item => (
          <div key={item.id} className="Restaurant__item">
            <div className="Restaurant__item-name">{item.name}</div>
            <div className="Restaurant__item-rating">
              <StaticRating ratings={sampleRatings()} size="20" />
            </div>
          </div>
        ))}
      </div>

      <AdminOnly>
        <div className="Restaurant__divider" />

        <div className="Restaurant__actions">
          <StyledLink to={`/restaurants/${restaurant.id}`}>
            <Button icon="pencil-alt" default>
              Edit Restaurant
            </Button>
          </StyledLink>
        </div>
      </AdminOnly>
    </StyledRestaurant>
  );
};

export default Restaurant;
