import React from 'react';
import styled from 'styled-components';

import theme from '../styles/theme';

import Label from './Label';

const StyledInput = styled.input`
  width: 100%;
  min-height: 38px;
  font-size: 16px;
  line-height: 1.5;
  padding: 2px 8px;
  background-color: hsl(0, 0%, 100%);
  border: 1px solid hsl(0, 0%, 80%);
  border-radius: 4px;
  box-shadow: none;
  color: ${theme.font_primary};
  font-family: 'Maven Pro', Helvetica, sans-serif;

  outline: none;
  transition: background 300ms, border-color 300ms, padding 300ms;

  &:focus {
    border-color: ${theme.A400};
    box-shadow: 0 0 0 1px ${theme.A400};
  }

  &:disabled {
    background: transparent;
    border-color: transparent;
    padding: 0;
  }
`;

const Input = ({ label, style, ...props }) => (
  <div style={style}>
    {label && <Label>{label}</Label>}
    <StyledInput type="text" autoComplete="off" {...props} />
  </div>
);

export default Input;
