import React, { useCallback } from 'react';
import styled, { css } from 'styled-components';
import { useQuery } from '@apollo/client';
import { useDispatch, useSelector } from 'react-redux';
import get from 'lodash/get';
import noop from 'lodash/noop';

import { LOCATIONS } from '../../lib/api';
import { actions } from '../../modules/modal';
import { selectors } from '../../modules/user';
import theme, { TRANSITION_FAST } from '../../styles/theme';

const StyledCell = styled.div`
  position: relative;
  min-height: 115px;
  padding: 36px 8px 8px;
  background: #fff;
  border-top: 1px solid #eee;
  border-right: 1px solid #eee;

  ${props => props.isWeekend && `background: #f7f7f7`};
  ${props => !props.isCurrentMonth && `color: #bababa`};

  @media (min-width: 800px) {
    &:nth-child(-n + 7) {
      border-top: none;
    }

    &:nth-child(7n) {
      border-right: none;
    }
  }

  @media (max-width: 800px) {
    min-height: 75px;
    border-right: none;

    &:first-child {
      border-top: none;
    }

    ${props => !props.isCurrentMonth && `display: none`};
    ${props => props.isToday && `border-left: 4px solid ${theme.A500}`};
  }

  .Cell__backdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    border-bottom: 3px solid transparent;
    transition: border-color ${TRANSITION_FAST};

    ${props => props.isAdmin && 'cursor: pointer'};

    &:hover {
      ${props => props.isAdmin && `border-bottom-color: ${theme.A500}`};
    }
  }

  .Calendar__details {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 8px;
    right: 8px;
    font-weight: 500;
    font-size: 18px;
    line-height: 1;
    z-index: 1;
    pointer-events: none;

    ${props =>
      props.isToday &&
      css`
        top: 2px;
        right: 2px;
      `};

    .Calendar__day {
      display: none;
      align-self: flex-end;
      font-size: 14px;
      margin-right: 5px;

      @media (max-width: 800px) {
        display: inline-flex;
        ${props => props.isToday && `margin-bottom: 8px`};
      }
    }

    .Calendar__number {
      display: inline-flex;

      ${props =>
        props.isToday &&
        css`
          align-items: center;
          justify-content: center;
          color: #fff;
          background: ${theme.A500};
          width: 30px;
          height: 30px;
          border-radius: 50%;
        `};
    }
  }

  .Calendar__content {
    position: relative;
    z-index: 1;
    ${props => !props.isCurrentMonth && `opacity: 0.9`};
  }
`;

const CalendarCell = ({ children, isAdmin, moment, ...props }) => {
  const dispatch = useDispatch();
  const { data: locationsData } = useQuery(LOCATIONS);
  const location = useSelector(selectors.settingLocation);

  const onCreateLunch = useCallback(() => {
    const locationSelection = get(locationsData, 'locations', []).find(l => l.id === location);
    dispatch(
      actions.showModal('editLunch', {
        details: { creating: true, location: locationSelection, moment },
      })
    );
  }, [locationsData, location, moment, dispatch]);

  return (
    <StyledCell isAdmin={isAdmin} {...props}>
      <div className="Cell__backdrop" onClick={isAdmin ? onCreateLunch : noop} />
      {children}
    </StyledCell>
  );
};

export default CalendarCell;
