import React from 'react';
import styled from 'styled-components';
import sample from 'lodash/sample';

import GREETINGS from '../constants/greetings';
import useUser from '../hooks/useUser';

const StyledGreeting = styled.div`
  font-size: 14px;
`;

const setContent = html => ({ __html: html });

const Greeting = () => {
  const { data } = useUser();
  const greeting = sample(GREETINGS)(data.given_name);
  return <StyledGreeting dangerouslySetInnerHTML={setContent(greeting)} />;
};

export default Greeting;
