import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useQuery } from '@apollo/client';
import styled from 'styled-components';

import theme from '../../styles/theme';
import { LOCATIONS } from '../../lib/api';
import { actions, selectors } from '../../modules/user';

import Select from '../Select';

const StyledFilter = styled.div`
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  z-index: 99;

  .Filter__top {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin: 0 0 3px;
  }

  .Filter__left {
    flex: 0 0 auto;
    margin-right: 6px;
  }

  .Filter__right {
    margin-top: 1px;
    font-weight: 700;
  }

  .Filter__bottom {
    color: ${theme.font_secondary};
    font-style: italic;
    font-size: 60%;
  }

  .Select__control {
    cursor: pointer;
    width: 150px;
    min-height: 0;
    border: none;
    background: transparent;
    box-shadow: none;
    padding: 0;

    &:hover {
      border: none;
    }
  }

  .Select__indicators {
    display: none;
  }

  .Select__value-container {
    padding: 0;
  }

  .Select__single-value {
    color: ${theme.font_primary};
    margin-left: 0;
    text-decoration: underline;
  }

  .Select__option {
    text-align: left;
    color: ${theme.font_primary};
  }
`;

const LocationFilter = () => {
  const dispatch = useDispatch();
  const { data, loading, error } = useQuery(LOCATIONS);

  const location = useSelector(selectors.settingLocation);
  const onChangeLocation = useCallback(
    ({ value }) => {
      dispatch(actions.updateUserLocation(value));
    },
    [dispatch]
  );

  const options = useMemo(() => {
    if (loading) return [];
    if (error) return [];

    const locations = data.locations.map(location => ({
      label: location.name,
      value: location.id,
    }));

    return [{ label: 'All Locations', value: 'all' }, ...locations];
  }, [data, loading, error]);

  return (
    <StyledFilter>
      <div className="Filter__top">
        <div className="Filter__left">Viewing Lunches for </div>{' '}
        <div className="Filter__right">
          <Select
            classNamePrefix="Select"
            options={options}
            isSearchable={false}
            placeholder=""
            onChange={onChangeLocation}
            value={options.filter(({ value }) => value === location)}
          />
        </div>
      </div>
      <div className="Filter__bottom">click location to update filter</div>
    </StyledFilter>
  );
};

export default LocationFilter;
