import React from 'react';
import orderBy from 'lodash/orderBy';

import useUser from '../../hooks/useUser';
import moment from '../../lib/moment';
import ButtonLink from '../ButtonLink';
import ButtonIcon from '../ButtonIcon';
import CalendarCell from './CalendarCell';
import CalendarLunch from './CalendarLunch';
import StyledCalendar, { CalendarTitle, CalendarHeaders, CalendarGrid } from './Calendar.styled';

const MAX_DATES = 42;

const generateCalendar = month => {
  const headers = [];
  const days = [];
  const today = moment();

  const start = moment(month)
    .startOf('month')
    .startOf('week');

  let current = moment(start);

  for (let i = 0; i < MAX_DATES; i++) {
    if (i < 7) headers.push(current.format('ddd'));

    days.push({
      moment: moment(current),
      date: current.format('MM/DD/YYYY'),
      day: current.format('ddd'),
      weekday: current.format('dddd'),
      number: current.format('D'),
      month: current.format('MMMM'),
      year: current.format('YYYY'),
      isToday: current.isSame(today, 'day'),
      isWeekend: [0, 6].includes(current.day()),
      isMWF: [1, 3, 5].includes(current.day()),
      isCurrentMonth: current.isSame(month, 'month'),
    });

    current = current.add(1, 'd');
  }

  return { headers, days, rows: Math.floor(days.length / 7) };
};

const getLunchesForDate = (date, lunches) => {
  const lunchesForDate = lunches.filter(lunch => lunch.date === date);
  return orderBy(lunchesForDate, ['location.name', 'name']);
};

const Calendar = ({ data, month, onRemove, onCurrent, onNext, onPrevious }) => {
  const { isAdmin } = useUser();
  const { headers, days, rows } = generateCalendar(month);

  const lunches = data
    ? data.lunches.map(lunch => ({
        ...lunch,
        date: moment(lunch.date, 'YYYY-MM-DD').format('MM/DD/YYYY'),
      }))
    : [];

  return (
    <StyledCalendar rows={rows}>
      <CalendarTitle>
        <div className="Calendar__left" onClick={onPrevious}>
          <ButtonIcon icon="arrow-circle-left" transparent />
        </div>
        <div className="Calendar__title">
          {month.format('MMMM YYYY')}
          {!moment().isSame(month, 'month') ? (
            <div className="Calendar__current">
              <ButtonLink onClick={onCurrent}>Current Month</ButtonLink>
            </div>
          ) : null}
        </div>
        <div className="Calendar__right" onClick={onNext}>
          <ButtonIcon icon="arrow-circle-right" transparent />
        </div>
      </CalendarTitle>
      <CalendarHeaders>
        {headers.map(header => (
          <div key={header} className="Calendar__header">
            {header}
          </div>
        ))}
      </CalendarHeaders>
      <CalendarGrid>
        {days.map(day => (
          <CalendarCell
            key={`${day.month}__${day.number}`}
            moment={day.moment}
            isAdmin={isAdmin}
            isToday={day.isToday}
            isMWF={day.isMWF}
            isWeekend={day.isWeekend}
            isCurrentMonth={day.isCurrentMonth}
          >
            <div className="Calendar__details">
              <div className="Calendar__day">{day.day}</div>
              <div className="Calendar__number">{day.number}</div>
            </div>
            <div className="Calendar__content">
              {getLunchesForDate(day.date, lunches).map(lunch => (
                <CalendarLunch key={lunch.id} isAdmin={isAdmin} onRemove={onRemove} moment={day.moment} lunch={lunch} />
              ))}
            </div>
          </CalendarCell>
        ))}
      </CalendarGrid>
    </StyledCalendar>
  );
};

export default Calendar;
