import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { connect } from 'react-redux';

import { selectors } from '../modules/modal';

import AlertModal from './Modals/AlertModal';
import ConfirmModal from './Modals/ConfirmModal';
import LunchModal from './Modals/LunchModal';

const types = {
  alert: <AlertModal />,
  confirm: <ConfirmModal />,
  editLunch: <LunchModal />,
};

const ModalConductor = ({ modals = [] }) => {
  const getModalComponent = type => types[type] || null;

  return (
    <AnimatePresence>
      {modals.map(modal => {
        const Modal = getModalComponent(modal.type);

        return Modal
          ? React.cloneElement(Modal, {
              key: modal.id,
              id: modal.id,
              ...modal.options,
            })
          : null;
      })}
    </AnimatePresence>
  );
};

const stateToProps = state => ({
  modals: selectors.modals(state),
});

export default connect(stateToProps)(ModalConductor);
