import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { connect } from 'react-redux';
import styled from 'styled-components';

import { selectors } from '../modules/toast';

import AlertToast from './Toasts/AlertToast';
import SuccessToast from './Toasts/SuccessToast';

const types = {
  alert: <AlertToast />,
  success: <SuccessToast />,
};

const ToastContainer = styled.div`
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 119;
`;

const ToastConductor = ({ toasts = [] }) => {
  const getToastComponent = type => types[type] || null;

  return (
    <ToastContainer>
      <AnimatePresence>
        {toasts.map(toast => {
          const Toast = getToastComponent(toast.type);

          return Toast
            ? React.cloneElement(Toast, {
                key: toast.id,
                id: toast.id,
                ...toast.options,
              })
            : null;
        })}
      </AnimatePresence>
    </ToastContainer>
  );
};

const stateToProps = state => ({
  toasts: selectors.toasts(state),
});

export default connect(stateToProps)(ToastConductor);
