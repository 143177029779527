import { combineReducers } from 'redux';
import { all } from 'redux-saga/effects';

import modal from './modal';
import toast from './toast';
import user, { userSaga } from './user';

export function* rootSaga() {
  yield all([userSaga()]);
}

export const rootReducer = combineReducers({
  modal,
  toast,
  user,
});
