import React from 'react';
import BaseSelect from 'react-select';
import Creatable from 'react-select/creatable';
import styled from 'styled-components';

import theme, { transparentize } from '../styles/theme';

import Label from './Label';

const StyledSelect = styled.div`
  .Select-control {
    background: transparent;
    border: none;
    border-bottom: 1px solid #dcdcdc;
    font-size: 24px;
    height: 50px;
    border-radius: 0;

    &:hover {
      box-shadow: none;
    }
  }

  .Select-input {
    height: 48px;
  }

  .Select-placeholder,
  .Select--single > .Select-control .Select-value {
    line-height: 48px;
  }

  .Select.is-focused > .Select-control {
    background: transparent;
    box-shadow: none;
    border-bottom-color: ${theme.A500};
  }

  .Select.is-focused:not(.is-open) > .Select-control {
    background: transparent;
    box-shadow: none;
    border-bottom-color: ${theme.A500};
  }

  .Select-menu-outer {
    border-radius: 0;
    border: none;
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.3);
  }

  .Select-option {
    color: ${theme.font_primary};
    font-size: 20px;

    &:last-child {
      border-radius: 0;
    }

    &.is-focused {
      background: ${transparentize(theme.A500, 0.7)};
      color: ${theme.white};
    }
  }
`;

const Select = ({ createable, style, label, ...props }) => {
  const createTheme = baseTheme => ({
    ...baseTheme,
    colors: {
      ...baseTheme.colors,
      primary: theme.A400,
      primary75: theme.A300,
      primary50: theme.A200,
      primary25: theme.A100,
    },
  });

  if (createable) {
    return (
      <StyledSelect style={style}>
        {label && <Label>{label}</Label>}
        <Creatable clearable={false} theme={createTheme} {...props} />
      </StyledSelect>
    );
  }

  return (
    <StyledSelect style={style}>
      {label && <Label>{label}</Label>}
      <BaseSelect clearable={false} theme={createTheme} {...props} />
    </StyledSelect>
  );
};

export default Select;
