import React from 'react';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import noop from 'lodash/noop';

import theme from '../styles/theme';

const StyledButton = styled.button`
  display: inline-flex;
  text-align: center;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  border-radius: 0;
  font-weight: 500;
  padding: 10px 15px;
  cursor: pointer;
  opacity: 0.9;
  border: none;
  outline: none;
  box-shadow: none;
  background: ${theme.A500};
  color: ${theme.white};
  transition: background 300ms, opacity 300ms;

  ${props => props.default && `background: ${theme.B800}`};
  ${props => props.default && `color: ${theme.font_secondary}`};

  ${props => props.alert && `background: ${theme.red}`};
  ${props => props.alert && `color: ${theme.white}`};

  ${props => props.dark && `background: rgba(0, 0, 0, 0.2)`};
  ${props => props.dark && `border: 1px solid rgba(0, 0, 0, 0.2)`};

  ${props => props.square && `padding: 15px`};

  ${props => props.transparent && `background: transparent`};

  ${props => props.iconOnly && `padding: 8px`};
  ${props => props.iconOnly && `font-size: 16px`};
  ${props => props.iconOnly && `margin: 0`};

  &:not([disabled]):hover {
    opacity: 1;
  }

  &[disabled] {
    opacity: 0.3;
    cursor: not-allowed;
  }

  .Button__icon {
    margin-right: 8px;
  }
`;

const Button = ({ children, icon, iconOnly, disabled, onClick, ...rest }) => (
  <StyledButton iconOnly={iconOnly} disabled={disabled} onClick={disabled ? noop : onClick} {...rest}>
    {icon && <FontAwesomeIcon className="Button__icon" icon={icon} />}
    {children}
  </StyledButton>
);

export default Button;
